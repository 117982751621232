.homepage {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.img_href img {
    width: 142px;
}

.home_icon img {
    width: 42px;
}

.content_filling{
    display: flex;
    justify-content: space-between;
}

.left_side {
    max-width: 450px;
    display: flex;
    flex-direction: column;
}

.right_side {
    width: 350px;
    display: flex;
    flex-direction: column;
    margin-top:45px;
}

.paragraph {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: justify;
    margin: 0;
    line-height: 200%;
}

li.paragraph {
    margin-left: 20px;
}

.facebook {
    width: 131px;
}

.facebook_link {
    text-decoration: none;
    color: black;
}

.facebook_link:hover{
    color: orangered;
    text-decoration: underline;
}

.img_href {
    margin-top: 150px;
}