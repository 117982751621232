.contatti {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contatti_paragraph{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    text-align: justify;
    margin: 10px 0;
    max-width: 500px;
}