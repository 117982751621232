.line {
    margin-top: 50px;
    display: flex;
    width: 900px;
    height: 4px;
    color: black;
    background-color: black;
}

.footer_text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10px;
    color: #000000;
    margin-top: 7px;
    padding-bottom: 10px;
}