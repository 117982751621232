.header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

nav {
    display: flex;
    width: 910px;
    background-color: #FF0000;
    height: 150px;
}

.header_image img {
    width: 910px;
    height: 136px;
}
.image_span {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: auto;
}