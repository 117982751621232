* {
    margin: 0;
    padding: 0;
}

#italiano {
    left: 145px;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    text-decoration: none;
    font-size: 24px;
    position: relative;
    top: 310px;
    font-weight: bold;
}

#inglese {
    left: 302px;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
    color: #000;
    text-decoration: none;
    font-size: 24px;
    position: relative;
    top: 310px;
    font-weight: bold;
}

img.language_background {
    margin: 0;
    padding: 0;
    position: absolute;
}
.row{
    margin:0 auto;
    width: 618px;
    padding-top:50px;
}